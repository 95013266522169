import clsx from "clsx"
import { useRecoilValue } from "recoil"
import { LanguageState } from "../../status/LanguageState"
import enJson from "../../assets/locales/en/index.json"
import jaJson from "../../assets/locales/ja/index.json"
import { getLanguageForLocalStorage } from "../../utility/get"

interface Props {
    n: string
}

type MyObjectType = {
    [key: string]: string;
};

function isKeyOfMyObjectJa(key: any): key is keyof typeof jaJson {
    return key in jaJson
}
function isKeyOfMyObjectEn(key: any): key is keyof typeof enJson {
    return key in enJson
}

export const lt = (n:string, state?:any) => {
    if( typeof window === "undefined" ) return
    let language = state || getLanguageForLocalStorage()
    let text:any = n
    switch (language) {
        case "ja":
            if (isKeyOfMyObjectJa(n)) {
                text = jaJson[n]
            }
            break;
    
        case "en":
            if (isKeyOfMyObjectEn(n)) {
                text = enJson[n]
            }
            break;
    }
    return text
}