// import React from 'react'
import { gsap } from "gsap"
import { CustomEase } from "gsap/CustomEase"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
// import browser from 'browser-detect'

gsap.registerPlugin(CustomEase)
gsap.registerPlugin(ScrollToPlugin)
let customEase = CustomEase.create("custom", "M0,0 C0.356,-0.002 0.298,0.608 0.4,0.8 0.506,1 0.764,1 1,1")

// Hook
function SmoothScroll(targetId:string, offset = 100)
{
    // const { mobile }  = browser()
    // let spOffset = 0
    // if( mobile ){
    //     spOffset = 64
    // }

    if(!targetId) return

    let t = document.getElementById(targetId.replace( /#/g , '' ))
    let header = document.getElementById('header')
    if(!header) return
    const h = header.getBoundingClientRect().height
    if( t ){
        let y = t.getBoundingClientRect().top - h - 16 + window.scrollY
        y = y < 0 ? 0 : y
        
        gsap.to(window,{
            duration: 0.6,
            scrollTo: 0,
            ease: customEase,
        })
    }
}

export { SmoothScroll }