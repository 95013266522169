import * as React from "react"
// import Logo from "../../assets/images/common__header__logo.svg"
import { urlFcFooterMenuList, urlFooterMenuList, urlSns  } from "../functional/urls"
import clsx from "clsx"
import { Instagram, Twitter } from "react-feather"
import SNsLinks from "../parts/SnsLinks"
import { MyLink } from "../elements/Link"
import { globalHistory } from '@reach/router'
import { useRecoilValue } from "recoil"
import { LanguageState } from "../../status/LanguageState"
import { lt } from "../parts/LanguageText"


interface Props {
}

export default function Footer({}:Props)
{
	const language = useRecoilValue(LanguageState)
	const { pathname } = globalHistory.location
	let footerMenuList = pathname.match(/member/g) ? urlFcFooterMenuList() : urlFooterMenuList()
	const isMember = pathname.match(/\/member/g)
	return (
		<footer className="bg-main text-white">
			<div className="py-20 cmn_px">
				<SNsLinks/>
				{ isMember && <p className="text-white text-center text-xs tb:text-sm mt-10 leading-[2.0em]">
					{lt("footer_txt_01")}
				</p> }
			</div>
			<div className="px-11 py-4 border-t-brdr_black_01 border-t-[1px]">
				<div className="text-center tb:text-left tb:flex items-center justify-between">
					<nav className="my-6 tb:my-0">
						<ul className={clsx(
							"tb:flex flex-wrap items-center justify-start text-xs",
							"gap-x-5 gap-y-1"
						)}>
							{ footerMenuList.map(({label, to},i)=>{
								return (
									<li key={i} className="block tb:inline-block my-[1em]">
										<MyLink to={to}>{label}</MyLink>
									</li>
								)
							})}
						</ul>
					</nav> 
					<small className="block mt-6 tb:mt-0">© {new Date().getFullYear()} {`${process.env.GATSBY_COPYRIGHT}`}</small>
				</div>
			</div>
		</footer>
	)
}