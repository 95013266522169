import clsx from "clsx"
import { MyLink } from "../elements/Link"
import { globalHistory } from "@reach/router"
import WatanabeLogo from "../elements/logos/WatanabeLogo"
import MainLogo from "../elements/logos/MainLogo"

export default function Logos({
    isBlendMode,
	isModal,
}:{
    isBlendMode?:boolean
	isModal?:boolean
}){
    const { pathname } = globalHistory.location
    const isMember = pathname.match(/member/g)
	const homeUrl = isMember ? "/member/" : "/"
    const commonClass = "z-50 fixed"
	const blendModeClass = "mix-blend-difference"
    return(
        <>
				{ isMember ?
					<>
						<MyLink to={homeUrl} className={clsx(
							"block top-5 left-6 w-[64px]",
							!isModal && "tb:top-6 tb:left-11 tb:w-[106px]",
							commonClass
						)}>
							<h1 className={clsx(
								"hidden",
								!isModal && "tb:block"
							)}>
								<MainLogo w={106} h={40}/>
							</h1>
							<h1 className={clsx(
								"block",
								!isModal && "tb:hidden"
							)}>
								<MainLogo w={64} h={24}/>
							</h1>
						</MyLink>
						{/* <MyLink to={process.env.GATSBY_URL_WATANABE!} blank className={clsx(
							"top-[19px] left-[104px] inline-block",
							!isModal && "tb:top-[32px] tb:left-[172px]",
							commonClass,
						)}>
							<WatanabeLogo/>
						</MyLink> */}
					</>:
					<>
						<MyLink to={homeUrl} className={clsx(
							"block top-4 left-6 w-auto",
							!isModal && "tb:top-5 tb:left-11 tb:w-auto",
							isBlendMode && blendModeClass,
							commonClass
						)}>
							<h1 className={clsx(
								"block text-[24px] w-auto font-en font-bold",
								!isModal && "tb:text-[32px]",
							)}>
								JUN SHISON
							</h1>
						</MyLink>
						{/* <MyLink to={process.env.GATSBY_URL_WATANABE!} blank  className={clsx(
							"top-[20px] left-[196px] inline-block",
							!isModal && "tb:top-[31px] tb:left-[272px] w-auto",
							commonClass,
						)}>
							<WatanabeLogo/>
						</MyLink> */}
					</>
				}
			</>
    )
}