import { atom } from 'recoil'
// import { recoilPersist } from 'recoil-persist'

// const { persistAtom } = recoilPersist()

const LOCAL_STORAGE_KEY = "language"
const getToLocalStorage = () => {
    if (typeof window === "undefined") {
        return ""
    }
    const value = window.localStorage.getItem(LOCAL_STORAGE_KEY)
    return value ? JSON.parse(value) : ""
}
  const saveToLocalStorage = (value: string) => {
    if (typeof window === "undefined") {
        return
    }
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(value))
}

export const LanguageState = atom<"ja"|"en">({
    key: 'languageState',
    default: getToLocalStorage() || "ja",
    effects_UNSTABLE: [
        ({ onSet }) => {
            onSet((newValue) => {
                saveToLocalStorage(newValue);
            });
        },
    ],
})