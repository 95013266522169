import * as React from "react"
import Svg from "/src/assets/images/common__icn__menu.svg"

interface Props {
    width?: number
    height?: number
}

export default function MenuIcon({width, height}:Props){
    return(
        <Svg width={width || 32 } height={height || 32} title=""/>
    )
}