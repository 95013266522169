
import React, { useRef } from "react"
import { gsap, Expo } from 'gsap'
import { useTransition } from "./TransitionLayout"

export default function Loading() {
    const { transitionState, duration, delay } = useTransition() as any
    // const q = useStaticQuery(graphql`
    //     query {
    //         logo : file(relativePath: { eq: "header__logo__daisen-white.svg" }) {
    //             publicURL
    //         }
    //     }
    // `)
    const containerRef:any = useRef()
    const titleRef:any = useRef()
    const loading:any = useRef(true)
    const progressRef:any = useRef()

    React.useEffect(() => {
        console.log("transition",transitionState)
        //start hide
        if (transitionState === 4) {
            if (!loading.current) return
            loading.current = false
            startHideContainer({ target: containerRef.current, duration, delay })
            startProgressbar({target:progressRef.current, duration, delay })
            
        } else
            if (transitionState === 1) {
                //start show
                if (loading.current) return
                loading.current = true
                startShowContainer({ target: containerRef.current, duration, delay })
                startShowProgressbar({target:progressRef.current, duration, delay })
            }

        if (transitionState === -1) {
            //browser back
            if (loading.current) return
            loading.current = true
            quiqShowContainer({ target: containerRef.current, duration, delay })
            quiqShowProgressbar({target:progressRef.current, duration, delay })
        }
    }, [transitionState, delay, duration])



    return (
        <div className="fixed top-0 left-0 w-full h-full bg-bg_gray z-[99999]" ref={containerRef}>
            <div className="w-full h-full flex items-center justify-center">
                <div>
                    <div ref={titleRef} className="font-bold font-en font-base">
                        LOADING
                    </div>
                    <div className="mt-1 border-[1px] border-black h-1 w-full rounded-full overflow-hidden gradient">
                        <div ref={progressRef} className="scale-x-[1%] origin-left w-full h-full bg-black"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}


type TransProps = {
    target: any
    delay: number
    duration: number
}

function startProgressbar({ target, delay, duration }:TransProps) {
    let tl = gsap.timeline()
    tl.set(target, {
        scaleX: 0.01,
    })
    const random = Math.floor(Math.random() * 90)*0.01
    tl.to(target, {
        ease: Expo.easeInOut,
        duration: duration/2,
        scaleX: 1,
    })
}
function startShowProgressbar({ target, delay, duration }:TransProps) {
    let tl = gsap.timeline()
    tl.set(target, {
        scaleX: 0.01
    })
}
function quiqShowProgressbar({ target, delay, duration }:TransProps) {
    let tl = gsap.timeline()
    tl.set(target, {
        scaleX: 0.01,
    })
}

function startHideContainer({ target, delay, duration }:TransProps) {
    let tl = gsap.timeline()
    tl.set(target, {
        // x: `0%`,
        opacity: 1,
        scale: 1.0,
    })
        // .to(target, {
        //     duration: duration/5 * 1,
        //     marginTop: 100,
        // })
        .to(target, {
            ease: Expo.easeInOut,
            delay: delay,
            duration: duration / 5 * 5,
            // display: 'none',//svg waveアニメーションと相性がわるい
            // x: `100%`,
            opacity: 0,
            scale: 1.0,
            onComplete: () => {
                gsap.set(target, {
                    // x: `-100%`,
                    opacity: 0,
                    scale: 1.5,
                    display: "none"
                })
            }
        })
}
function startShowContainer({ target, delay, duration }:TransProps) {
    let tl = gsap.timeline()
    tl.set(target, {
        // x: `-100%`,
        opacity: 0,
    })
    tl.to(target, {
        // ease: Back.easeInOut.config(1.7),
        ease: Expo.easeIn,
        display: 'flex',
        duration: duration / 5 * 2,
        marginTop: 0,
        opacity: 0,
    })
    .to(target, {
        ease: Expo.easeInOut,
        duration: duration / 5 * 3,
        opacity: 1,
        scale: 1.0,
        // x: `0%`,
        marginTop: 0,
    })
}

function quiqShowContainer({ target, delay, duration }:TransProps) {
    gsap.set(target, {
        // x: `0%`,
        opacity: 1,
        scale: 1.3,
        display: 'flex',
        marginTop: 0,
    })
    gsap.to(target, {
        ease: Power4.easeInOut,
        delay: delay,
        duration: duration,
        opacity: 1,
        // display: 'none',
        onComplete: () => {
            gsap.set(target, {
                opacity: 0,
            })
        }
    })
}
