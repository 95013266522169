import * as React from "react"
import Svg from "/src/assets/images/common__logo__main.svg"

interface Props {
    w?: number
    h?: number
    viewBox?: string
}

export default function MainLogo({w,h, viewBox}:Props){
    return(
        <Svg width={!w ? null : w} height={!h ? null : h} viewBox={viewBox === "" ? "" : "0 0 107 40" } title="JUN SHISON"/>
    )
}