import { lt } from "../parts/LanguageText"

type menuUrls = {
    label: string
    en_label?: string
    to: string,
    blank?: boolean,
}

export const urlMenuList:menuUrls[] = [
    {
        label: "NEWS",
        to: "/news/"
    },{
        label: "BIOGRAPHY",
        to: "/biography/"
    },{
        label: "CONTACT",
        to: "/contact/"
    },{
        label: "SHOP",
        to: "https://ssj-shop.net",
        blank: true,
    },{
        label: "FANCLUB",
        to: "login"
    }
]

export const urlFooterMenuList = () => [
    {
        label: lt("footer_nav_01"),
        to: "/about/"
    },{
        label: lt("footer_nav_02"),
        to: "/contact/"
    },{
        label: lt("footer_nav_03"),
        to: `${process.env.GATSBY_MODD_URL_PRIVACY}`
    }
]


export const urlModalMenuFooterMenuList = () => [
    {
        label: lt("footer_nav_01"),
        to: "/about/"
    },
    // {
    //     label: lt("footer_nav_02"),
    //     to: "/contact/"
    // },
    {
        label: lt("footer_nav_03"),
        to: `${process.env.GATSBY_MODD_URL_PRIVACY}`
    }
]

export const urlFcMenuList:menuUrls[] = [
    {
        label: "TOPICS",
        to: "/member/topics"
    },{
        label: "PRIVATE",
        to: "/member/private"
    },{
        label: "ALBUM",
        to: "/member/album"
    },{
        label: "MOVIE",
        to: "/member/movie"
    },{
        label: "REPORT",
        to: "/member/report"
    },{
        label: "TICKET",
        to: "/member/ticket"
    },{
        label: "MEMBER ID",
        to: "/member/account/memberid"
    },{
        label: "DOWNLOAD",
        to: "/member/download",
    },{
        label: "LETTER",
        to: "/member/letter"
    },{
        label: "SHOP",
        to: "https://ssj-shop.net",
        blank: true,
    },{
        label: "LOGOUT",
        to: "logout"
    }
]

export const urlFcFooterMenuList = () => [
    {
        label: lt("footer_fc_nav_01"),
        to: `${process.env.GATSBY_MODD_URL_TERM}`
    },{
        label: lt("footer_fc_nav_02"),
        to: `${process.env.GATSBY_MODD_URL_HELP}`
    },{
        label: lt("footer_fc_nav_03"),
        to: "/contact/"
    },{
        label: lt("footer_fc_nav_04"),
        to: `${process.env.GATSBY_MODD_URL_PRIVACY}`
    },{
        label: lt("footer_fc_nav_05"),
        to: `${process.env.GATSBY_MODD_URL_TOKUTEI}`
    }
]

export const urlFcModalMenuFooterMenuList = () => [
    {
        label: lt("footer_fc_nav_01"),
        to: `${process.env.GATSBY_MODD_URL_TERM}`
    },{
        label: lt("footer_fc_nav_02"),
        to: `${process.env.GATSBY_MODD_URL_HELP}`
    },{
        label: lt("footer_fc_nav_03"),
        to: "/contact/"
    },{
        label: lt("footer_fc_nav_04"),
        to: `${process.env.GATSBY_MODD_URL_PRIVACY}`
    },{
        label: lt("footer_fc_nav_05"),
        to: `${process.env.GATSBY_MODD_URL_TOKUTEI}`
    }
]


export const urlSns = {
    twitter: `${process.env.GATSBY_SNS_URL_TWITTER}`,
    instagram: `${process.env.GATSBY_SNS_URL_INSTAGRAM}`,
}
