
import { globalHistory } from '@reach/router'
import { Link, navigate } from 'gatsby-link'
import { useRecoilState, useRecoilValue, } from "recoil"
import { LanguageState } from "../../status/LanguageState"
import { ModalMenuState } from "../../status/ModalMenuState"
import { SmoothScroll } from "../../utility/smoothScroll"
import { useTransition } from "../layouts/TransitionLayout"
// import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
// import { modalMenuState } from '@states/modal-menu-state'

// import { useStore } from '@hooks/useStore'
const MyLink = ({
	children,
	to,
	blank,
	scroll,
	onClick,
	isStopPropagation,
	...other
}: {
	className?: string
	children: any
	to: string
	blank?: boolean
	scroll?: string
	onClick?: any
	isStopPropagation?: boolean
}) => {
	const language = useRecoilValue(LanguageState)
	const [isModalMenu, setIsModalMenu] = useRecoilState(ModalMenuState)
	const { transitionState, setTranstionState, duration } = useTransition() as any

	let isInternal = /^\/(?!\/)/.test(to)
	const isHash = /^(!?#)/.test(to)
	let languageTo = `${language === "ja" ? "" : "/en"}${to}`
	languageTo = languageTo.replace(/-en$/g,"")
	// const { appStore } = useStore()

	if (isHash || scroll) {
		isInternal = true
	}

	const { location } = globalHistory

	const onClickHandler = (e:any) => {
		isStopPropagation && e.stopPropagation()
		e.preventDefault()
		if( transitionState !== 0){ return }

		if ( (location.pathname === languageTo) || (location.pathname === `${languageTo}/`) || !languageTo) {
			if (scroll) {
				// console.log(scroll)
				onClick && onClick()
				SmoothScroll(scroll)
			} else {
				onClick && onClick()
				SmoothScroll('#root')
			}
			return
		} else {

			onClick && onClick()
			setTranstionState(1)
			if( isModalMenu === true){
				setIsModalMenu(false)
			}
			setTimeout(() => {

				if( language !== "en" && languageTo.match(/\/en\//g) ){
					console.log("href========>")
					window.location.href = languageTo
				} else {
					console.log("nav========>")
					navigate(languageTo)
				}
				if (scroll) {
					setTimeout(() => {
						SmoothScroll(scroll)
					}, 1000)
				}
			}, 1 * 1000)
		}
	}

	// useEffect(()=>{
	// })
	
	return (
		<>
		{ isInternal ?
				<Link
					to={languageTo}
					onClick={onClickHandler}
					{...other}
				>
					{children}
				</Link>
			:
				<a href={to} target={blank ? "_blank" : ""} {...other}>
					{children}
				</a>
		}
		</>
	)
}

const MyNavigate = (path:string) => {
	// let modalMenuValue = useRecoilValue(modalMenuState)
	const { transitionState, setTranstionState, duration } = useTransition() as any
	setTranstionState(1)
	console.log(path)
	navigate(path)
}

export { MyLink, MyNavigate }