// import "@fontsource/dm-sans"
// import "@fontsource/dm-sans/500.css"
// import "@fontsource/dm-sans/700.css"
// import "@fontsource/dm-mono"
// import "@fontsource/dm-mono/500.css"

import { wrapWithProvider, wrapWithPage } from './gatsby-wrapper'

export const wrapRootElement = wrapWithProvider
export const wrapPageElement = wrapWithPage
