import * as React from "react"
import Svg from "/src/assets/images/common__icn__twitter.svg"

interface Props {
    width?: number
    height?: number
}

export default function Twitter({width, height}:Props){
    return(
        <Svg width={width || 20 } height={height || 20} title="twitter"/>
    )
}