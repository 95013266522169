export function getLanguageForLocalStorage(){
  let language = (window.localStorage.getItem("language") || "ja" )
  language = language.replace(/"/g,"")
  return language
}

export function getObjectSize(object:any):any {
    const jsonString = JSON.stringify(object);
    let sizeInBytes = new Blob([jsonString], { type: 'application/json' }).size;
    return sizeInBytes;
  }

export function escapeSpecialChars(value:any):any {
  if (typeof value === 'string') {
    return value.replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0');
  } else if (Array.isArray(value)) {
    return value.map(item => escapeSpecialChars(item));
  } else if (value !== null && typeof value === 'object') {
    let escapedObject:any = {}
    for (const key in value) {
      escapedObject[key] = escapeSpecialChars(value[key]);
    }
    return escapedObject;
  }
  return value;
}
