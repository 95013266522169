
import clsx from "clsx"
import { useLocalStorage } from "usehooks-ts"
import TranslateIcon from "../icons/TranslateIcon"
import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import { LanguageState } from "../../../status/LanguageState"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby-link"
import { useTransition } from "../../layouts/TransitionLayout"

interface Props {
    className?: string
}

export default function SwitchLanguageButton({
    className
}:Props){
    const { pathname } = useLocation()
    const [language, setLanguage] = useRecoilState(LanguageState)
    const { transitionState, setTranstionState, duration } = useTransition() as any
    // const [currentLang, setCurrentLang] = useState<"ja"|"en">("ja")
    const onClickHandler = () => {
        if( transitionState !== 0){ return }
        setTranstionState(1)
        setTimeout(() => {
        setLanguage(language === "ja" ? "en" : "ja")
            switch (language) {
                case "ja":
                    navigate(`/en${pathname}`)
                    break
                case "en":
                    let path = pathname
                    if( pathname.match(/\/en\//g) ){
                        path = path.replace(/\/en/g,"")
                    }
                    if( pathname.match(/-en$/g) ){
                        path = path.replace("-en","")
                    }
                    navigate(path)
                    break
            }
        },1000)
    }

    useEffect(()=>{
        if( pathname.match(/\/connect/g) ){
            return
        }
        //check
        if( pathname.match(/\/en/g) && language === "ja" ){
            setLanguage("en")
        } else
        if( !pathname.match(/\/en/g) && language === "en" ){
            setLanguage("ja")
        }
    },[])
    
    return(
        <div className={clsx(
            "inline-flex justify-center items-center gap-2 border-2 border-white text-sm font-en font-extrabold",
            "rounded-full min-w-[72px] h-[32px] cursor-pointer hover:opacity-50",
            className
        )} onClick={onClickHandler}>
            <span className="inline-block w-5 h-5"><TranslateIcon width={20} height={20}/></span>
            <span>{language.toUpperCase()}</span>
        </div>
    )
}