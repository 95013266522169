
import { urlSns } from "../functional/urls"
import clsx from "clsx"
import { MyLink } from "../elements/Link"
import Twitter from "../elements/icons/Twiiter"
import Instagram from "../elements/icons/Instagram"

interface Props {
    size?: number
    isPhotos?: boolean
}

export default function SNsLinks({isPhotos ,size = 48}:Props){
    return(
        <div className="overflow-hidden">
            <p className={clsx(
                "tb:text-base font-bold text-center font-en mb-1",
                isPhotos ? "text-[10px] tb:text-base" : "text-base"
            )}>
                Discover More with Me
            </p>
            <div className={clsx(
                "mt-3 flex items-center justify-center gap-3 tb:gap-12",
                "[&_svg]:w-full [&_svg]:h-auto",
            )}>
                <MyLink className={clsx(
                    "block",
                    isPhotos ? "w-[24px] h-[24px] tb:w-[48px] tb:h-[48px]" : "w-[48px] h-[48px]"
                )} to={urlSns.twitter!} blank><Twitter width={48} height={48}/></MyLink>
                <MyLink className={clsx(
                    "block",
                    isPhotos ? "w-[24px] h-[24px] tb:w-[48px] tb:h-[48px]" : "w-[48px] h-[48px]"
                )} to={urlSns.instagram!} blank><Instagram width={48} height={48}/></MyLink>
            </div>
        </div>
    )
}