import { urlFcFooterMenuList, urlFcMenuList, urlFcModalMenuFooterMenuList, urlFooterMenuList, urlMenuList, urlModalMenuFooterMenuList, urlSns } from "../functional/urls"
import MenuIcon from "../elements/icons/MenuIcon"
import CloseIcon from "../elements/icons/CloseIcon"
import clsx from "clsx"

import EnterIcon from "../elements/icons/EnterIcon"
import Twitter from "../elements/icons/Twiiter"
import Instagram from "../elements/icons/Instagram"
import { MyLink } from "../elements/Link"
import { globalHistory } from '@reach/router'
import { useEffect, useState } from "react"
import Logos from "../parts/Logos"
import { Variants, motion } from "framer-motion"
import { loginHandler, logoutHandler } from "../../utility/LoginHandler"
import { useEventListener } from "usehooks-ts"
import { useRecoilState, useRecoilValue } from "recoil"
import { ModalMenuState } from "../../status/ModalMenuState"
import { LanguageState } from "../../status/LanguageState"

interface Props {
}

let snsList = [
	{
		to: urlSns.twitter,
		Icon: Twitter
	},{
		to: urlSns.instagram,
		Icon: Instagram
	}
]


const ContainerAnimation:Variants = {
    open: {
      x: "0%",
      opacity: 1,
	  transition: {
        duration: 0.3,
        ease: "anticipate",
      },
    },
    init: {
      x: "100%",
      opacity: 0,
    },
    close: {
      x: "100%",
      opacity: 0,
    },
};

const NaviItemsAnimation = (i:number) => {
    const res:Variants =  {
		open: {
			x: "0%",
			opacity: 1,
			transition: {
			  duration: 0.3,
			  delay: 0.04 * i,
			  ease: "anticipate",
			},
		  },
		init: {
			x: "100%",
			opacity: 0,
		},
		close: {
			x: "100%",
			opacity: 0,
		},
	}
	return res
};

export default function ModalMenu({}:Props){
	const language = useRecoilValue(LanguageState)
	const { pathname } = globalHistory.location
	const [isModalMenu, setIsModalMenu] = useRecoilState(ModalMenuState)
	const modalHandler = () => {
		setIsModalMenu(!isModalMenu)
	}
	let menuList = pathname.match(/member/g) ? urlFcMenuList : urlMenuList
	let footerMenuList = pathname.match(/member/g) ? urlFcModalMenuFooterMenuList() : urlModalMenuFooterMenuList()
	const isMember = pathname.match(/member/g) ? true : false

	
	const onClickHandler = () => {
		if(isModalMenu){
			setIsModalMenu(false)
		}
	}
	return (
		<>
			<motion.button
				whileHover={{ scale: 1.1 }}
				whileTap={{ scale: 0.9 }}
				onClick={modalHandler}
				className={clsx(
					"mix-blend-difference w-8 h-8 inline-block fixed top-4 right-5 z-[5000]",
					isMember ? "fcnav:hidden" : "nav:hidden",
				)}>
				{ !isModalMenu ? <MenuIcon/> : <CloseIcon/> }
			</motion.button>
				<motion.div
					onClick={(e) => e.stopPropagation()}  
					variants={ContainerAnimation}
					initial="init"
					animate={isModalMenu ? "open" : "close"}
					exit="init"
					className={clsx(
						"fixed top-0 left-0 bg-white h-full w-full z-[4000]",
						// isModalOpen ? "block" : "hidden",
					)}
				>
				<div className="w-full h-full overflow-y-scroll pb-16 overflow-hidden">
					<nav className="">
						<ul className={clsx(
							"text-left font-en text-[28px] font-extrabold",
							"[&>li]:py-3 [&>li]:px-6 [&>li]:border-brdr_gray_01 [&>li]:border-b-[1px] [&_a]:inline-block [&_a]:align-middle" 
						)}>
							<li className="h-[64px]">
								<Logos isModal/>
							</li>
							{ menuList.map(({label,to, blank},i)=>{
								let isClick = to === "login" || to === "logout"
								return (
									<motion.li key={i}
										variants={NaviItemsAnimation(i)}
										initial="init"
										animate={isModalMenu ? "open" : "close"}
										exit="init"
									>
										{ !isClick && <MyLink to={to} className="" blank={blank}>
											{label} 
										</MyLink> }
										{ to === "login" &&
											<button onClick={()=> {
												onClickHandler()
												loginHandler(true)
											}} className="flex items-center">
												{label}<span className="-mb-[2px] ml-1 inline-block [&_path]:fill-[#000]">
													<EnterIcon width={24} height={24}/>
												</span>
											</button>
										}
										{ to === "logout" &&
											<button onClick={()=>{
												onClickHandler()
												logoutHandler({cb:()=>{}})
											}} className="">
												{label}
											</button>
										}
									</motion.li>
								)
							})}
						</ul>
						<ul className="text-left text-[16px] font-bold my-8">
							{ footerMenuList.map(({label, to},i)=>{
								return (
									<li key={i} className="px-6 my-2">
										<MyLink to={to} className="inline-block">
											{label}
										</MyLink>
									</li>
								)
							})}
						</ul>
						<ul className="flex items-center justify-start px-6 -mx-3">
							{ snsList.map(({to, Icon},i)=>{
								return (
									<li key={i} className="mx-3">
										<MyLink to={to} className="inline-flex items-center justify-center bg-main rounded-full w-10 h-10" onClick={onClickHandler}>
											<Icon/>
										</MyLink>
									</li>
								)
							})}
						</ul>
					</nav>
				</div>
				<div className="absolute bottom-2 left-0 w-full text-center">
					<small className="text-xs text-txt_gray_02">© {new Date().getFullYear()} {`${process.env.GATSBY_COPYRIGHT}`}</small>
				</div>
			</motion.div>
		</>
	)
}